import { render, staticRenderFns } from "./book-edit.vue?vue&type=template&id=4641586e&scoped=true"
import script from "./book-edit.vue?vue&type=script&lang=js"
export * from "./book-edit.vue?vue&type=script&lang=js"
import style0 from "./book-edit.vue?vue&type=style&index=0&id=4641586e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4641586e",
  null
  
)

export default component.exports